import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { array, number, object, string } from 'yup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { createScoringTemplate } from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import FormikLabel from '../../components/formik/FormikLabel';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddScoringTemplate = () => {
    const {user} = useSelector(getAuth)
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            firstPlaceRegularRegular: '',
            secondPlaceRegularRegular: '',
            thirdPlaceRegularRegular: '',
            registrationRegularRegular: '',
            disqualificationRegularRegular: '',
            noShowRegularRegular: '',
            substitutionRegularRegular: '',
            firstPlaceFlagshipRegular: '',
            secondPlaceFlagshipRegular: '',
            thirdPlaceFlagshipRegular: '',
            registrationFlagshipRegular: '',
            disqualificationFlagshipRegular: '',
            noShowFlagshipRegular: '',
            substitutionFlagshipRegular: '',
            firstPlaceRegularOtse: '',
            secondPlaceRegularOtse: '',
            thirdPlaceRegularOtse: '',
            registrationRegularOtse: '',
            disqualificationRegularOtse: '',
            noShowRegularOtse: '',
            substitutionRegularOtse: '',
            firstPlaceFlagshipOtse: '',
            secondPlaceFlagshipOtse: '',
            thirdPlaceFlagshipOtse: '',
            registrationFlagshipOtse: '',
            disqualificationFlagshipOtse: '',
            noShowFlagshipOtse: '',
            substitutionFlagshipOtse: '',
        },
        validationSchema: object({
            // name: string().required(),
            // points: number("Should be a number").required("required"),
            firstPlaceRegularRegular:
                number('Should be a number').required('required'),
            secondPlaceRegularRegular:
                number('Should be a number').required('required'),
            thirdPlaceRegularRegular:
                number('Should be a number').required('required'),
            registrationRegularRegular:
                number('Should be a number').required('required'),
            disqualificationRegularRegular:
                number('Should be a number').required('required'),
            noShowRegularRegular: number('Should be a number').required('required'),
            substitutionRegularRegular:
                number('Should be a number').required('required'),
            firstPlaceFlagshipRegular:
                number('Should be a number').required('required'),
            secondPlaceFlagshipRegular:
                number('Should be a number').required('required'),
            thirdPlaceFlagshipRegular:
                number('Should be a number').required('required'),
            registrationFlagshipRegular:
                number('Should be a number').required('required'),
            disqualificationFlagshipRegular:
                number('Should be a number').required('required'),
            noShowFlagshipRegular: number('Should be a number').required('required'),
            substitutionFlagshipRegular:
                number('Should be a number').required('required'),
            firstPlaceRegularOtse:
                number('Should be a number').required('required'),
            secondPlaceRegularOtse:
                number('Should be a number').required('required'),
            thirdPlaceRegularOtse:
                number('Should be a number').required('required'),
            registrationRegularOtse:
                number('Should be a number').required('required'),
            disqualificationRegularOtse:
                number('Should be a number').required('required'),
            noShowRegularOtse:
                number('Should be a number').required('required'),
            substitutionRegularOtse:
                number('Should be a number').required('required'),
            firstPlaceFlagshipOtse:
                number('Should be a number').required('required'),
            secondPlaceFlagshipOtse:
                number('Should be a number').required('required'),
            thirdPlaceFlagshipOtse:
                number('Should be a number').required('required'),
            registrationFlagshipOtse:
                number('Should be a number').required('required'),
            disqualificationFlagshipOtse:
                number('Should be a number').required('required'),
            noShowFlagshipOtse:
                number('Should be a number').required('required'),
            substitutionFlagshipOtse:
                number('Should be a number').required('required'),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('scoring', values);
            let entries = Object.entries(values);
            for ( let i = 0; i < entries.length ; i++) {
                const [key, value] = entries[i]
                dispatch(
                    createScoringTemplate({
                        name: `${key}`,
                        points: `${value}`,
                    })
                );
            }
            resetForm();
        },
    });
    return (
        <PageWithCard heading="Add Scoring">
            <form
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                onSubmit={formik.handleSubmit}
            >
                <div className="grid grid-cols-5 gap-4">
                    <div>
                        <h3 className="font-medium text-lg text-center text-red-500">
                            Rank
                        </h3>
                        <div className="flex flex-col gap-8 mt-7">
                            <FormikLabel label="First Place" />
                            <FormikLabel label="Second Place" />
                            <FormikLabel label="Third Place" />
                            <FormikLabel label="Registration" />
                            <FormikLabel label="Disqualification" />
                            <FormikLabel label="No Show" />
                            <FormikLabel label="Substitution" />
                            {/* <label>First Place</label>
                        <label>Second Place</label>
                        <label>Third Place</label>
                        <label>Registration</label>
                        <label>Disqualification</label>
                        <label>No Show</label>
                        <label>Substitution</label> */}
                        </div>
                    </div>
                    <div>
                        <h3 className="font-medium text-lg text-center text-red-500">
                            Regular
                        </h3>
                        <div className="flex flex-col gap-4 mt-4">
                            <FormikInputGroup
                                formik={formik}
                                name="firstPlaceRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="secondPlaceRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="thirdPlaceRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="registrationRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="disqualificationRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="noShowRegularRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="substitutionRegularRegular"
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="font-medium text-lg text-center text-red-500">
                            Flagship
                        </h3>
                        <div className="flex flex-col gap-4 mt-4">
                            <FormikInputGroup
                                formik={formik}
                                name="firstPlaceFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="secondPlaceFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="thirdPlaceFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="registrationFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="disqualificationFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="noShowFlagshipRegular"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="substitutionFlagshipRegular"
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="font-medium text-lg text-center text-red-500">
                            RegularOTSE
                        </h3>
                        <div className="flex flex-col gap-4 mt-4">
                            <FormikInputGroup
                                formik={formik}
                                name="firstPlaceRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="secondPlaceRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="thirdPlaceRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="registrationRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="disqualificationRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="noShowRegularOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="substitutionRegularOtse"
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className="font-medium text-lg text-center text-red-500">
                            FlagshipOTSE
                        </h3>
                        <div className="flex flex-col gap-4 mt-4">
                            <FormikInputGroup
                                formik={formik}
                                name="firstPlaceFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="secondPlaceFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="thirdPlaceFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="registrationFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="disqualificationFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="noShowFlagshipOtse"
                            />
                            <FormikInputGroup
                                formik={formik}
                                name="substitutionFlagshipOtse"
                            />
                        </div>
                    </div>
                </div>
                {['Super User', 'Developer'].includes(user?.role?.name) && (
                    <div className="text-center mt-4">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                )}
            </form>
        </PageWithCard>
    );
};

export default AddScoringTemplate;
